import React from "react";
import Typography from "components/Typography";

import img1 from "../images/s6-p1.jpg";
import img2 from "../images/s6-p2.jpg";
import img3 from "../images/s6-p3.jpg";
import img4 from "../images/s6-p4.jpg";
import img5 from "../images/s6-p5.jpg";
import img6 from "../images/s6-p6.jpg";
import img7 from "../images/s6-p7.jpg";

const relaxImages = [
  {
    src: img1,
    name: "YUST",
    location: "Liège, Belgium",
  },
  {
    src: img2,
    name: "Hotel AMI",
    location: "Paris, France",
  },
  {
    src: img3,
    name: "Pillows Central",
    location: "Brussels, Belgium",
  },
  {
    src: img4,
    name: "Roomers",
    location: "Munich, Germany",
  },
  {
    src: img5,
    name: "Lindley",
    location: "Frankfurt, Germany",
  },
  {
    src: img6,
    name: "Hotel Wallace",
    location: "Paris, France",
  },
  {
    src: img7,
    name: "FranQ",
    location: "Antwerp, Belgium",
  },
];

function Section6(props) {
  return (
    <div className="py-8">
      <div className="mx-auto text-center">
        <div className="max-w-4xl mx-auto mb-6 px-4 sm:px-0">
          <Typography variant="h2" className="mb-6 ">
            The best of the best.
          </Typography>
          <Typography variant="caption2">
            Get a glimpse of our latest locations in Belgium, France and
            Germany. New hotels added weekly.
          </Typography>
        </div>
        <div className="relative flex justify-center">
          <div className="no-scrollbar overflow-x-auto snap-x relative flex gap-2 pb-2">
            {relaxImages.map((img, index) => (
              <div key={index} className="shrink-0">
                <img src={img.src} alt={img.title} className="h-56 w-56" />
                <Typography variant="bodySmall" className="text-left mt-2">
                  <strong>{img.name}</strong> - {img.location}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section6;
