import React from "react";
import Typography from "components/Typography";
import AppButton from "../components/AppButton";
import HotelsList from "../components/HotelsList/HotelsList";
import {hotels} from "../data/hotels";


function SectionWorkspaces(props) {
  return (
    <div className="py-8" id="workspaces">
      <div className="mx-auto text-center">
        <div className="max-w-4xl px-4 mx-auto mb-6 sm:px-0">
          <Typography variant="h2" className="mb-6 ">
          Book 100+ workspaces in a single App.
          </Typography>
          <Typography variant="caption2">
          Check out some of our mindblowing locations. <br />
Our selection of European hotels is growing every day.
          </Typography>
        <div className="relative flex justify-center pt-5">
            <HotelsList hotels={hotels.filter(h => [
              'YUST Antwerp',
              'The Hotel',
              'Pillows Grand Reylof',
              'A-Stay',
              'YUST Liège',
              'Hotel Indigo'
            ].indexOf(h.name.trim()) >=0 )}/>
        </div>
        <div className="pt-10">
            <AppButton
                as="a"
                to="/hotels"
                size="base"
                background="black"
                title="See more"
                />
        </div>
        </div>
      </div>
    </div>
  );
}

export default SectionWorkspaces;
