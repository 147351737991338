import * as React from "react";
import { Hero } from "../sections/Hero";
import { Section1 } from "../sections/Section1";
import Navbar from "../sections/Navbar";
import Seo from "../sections/seo";
import { Section2 } from "../sections/Section2";
import { Section3 } from "../sections/Section3";
import { Section4 } from "../sections/Section4";
import { SectionPlansComparison } from "../sections/SectionPlansComparison";
import Section6 from "../sections/Section6";
import { Faq } from "../sections/Faq";
import SocialMedia from "../sections/SocialMedia";
import WaitingListModal from "components/modal/WaitingListModal";
import WaitingListProvider from "contexts/WaitingListProvider";
import BusinessProvider from "../contexts/BusinessProvider";
import BusinessModal from "components/modal/BusinessModal";
import HotelProvider from "../contexts/HotelProvider";
import HotelModal from "../components/modal/HotelModal";
import SectionBusinessEssentials from "../sections/SectionBusinessEssentials";
import SectionWorkspaces from "../sections/SectionWorkspaces";
import SectionMemberBenefits from "../sections/SectionMemberBenefits";
import Footer from "../sections/Footer";
import CookieBanner from "components/CookieBanner";
export default function Index() {
  return (
    <div>
      <Seo />
      <SocialMedia />
      <HotelProvider>
        <BusinessProvider>
          <WaitingListProvider>
            <WaitingListModal />
            <BusinessModal />
            <HotelModal />
            <Navbar />
            <Hero />
            <Section1 />
            <SectionWorkspaces/>
            <SectionMemberBenefits/>
            <SectionPlansComparison />
            <SectionBusinessEssentials />
          </WaitingListProvider>
        </BusinessProvider>
      </HotelProvider>
      <Faq />
      <Footer />
      <CookieBanner />
    </div>
  );
}
