import React, { useRef, useEffect} from "react";
import heroImg from "images/hero.jpg";
import heroVideo from "images/hero.mp4";
import heroVideoWebm from "images/hero.webm";
import AppButton from "../components/AppButton";
import Typography from "components/Typography";

export const Hero = () => {
  const videoRef = useRef(null);
  useEffect(() => {
    videoRef.current.muted = true;
    videoRef.current.play();
  })
  return (
    <div className="relative mx-auto">
      <div className="absolute inset-0">
        <video ref={videoRef} preload="true" autoPlay playsInline autoplay="autoplay" muted loop="loop" playsinline className="absolute object-cover w-full h-full mx-auto">
            <source src={heroVideoWebm} type="video/webm"/>
            <source src={heroVideo} type="video/mp4"/>
            Your browser does not support the video tag.
        </video>
      <div className="absolute inset-0 bg-black opacity-20"></div>
        {/* <iframe 
        className="absolute w-full h-full" 
        src="https://www.youtube.com/embed/EgcArkgAlOY?controls=0&autoplay=1&mute=1&playsinline=1&enablejsapi=1&origin=https%3A%2F%2Fwww.ooofer.com&widgetid=1&loop=1&modestbranding=1&showinfo=0&enablejsapi=1&&widgetid=3" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
        </iframe> */}
      </div>
      <div className="relative h-[600px] sm:h-[694px] flex justify-center items-center text-center">
        <div>
          <Typography variant="title" className="px-4">
          Coworking solution & <br className="hidden sm:block" /> hotel loyalty program. <br className="hidden sm:block" /> All in one App. 
          </Typography>
          <div className="flex flex-row items-center justify-center gap-4 mt-16">
            <AppButton
              as="a"
              to="/redirect-to-store"
              size="base"
              background="white"
              title="Download the app"
            />
            <AppButton
              as="a"
              to="#overview"
              size="base"
              background="outline"
              title="Learn more"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
