import React, { useContext } from "react";
import Typography from "components/Typography";
import AppButton from "components/AppButton";
import { BusinessContext } from "contexts/BusinessProvider";

function SectionBusinessEssentials(props) {
  const { setOpen: setBusinessOpen } = useContext(BusinessContext);
  return (
    <div className="px-4 py-10 sm:px-0">
      <div className="max-w-4xl mx-auto text-left">
        <Typography variant="h3" className="mb-6 text-4xl sm:text-5xl ">
        Corporate solutions
        </Typography>
        <Typography variant="caption2" className="mb-6">
        Ooofer is an ideal solution for <strong>companies</strong>, small or large. <br /> 
        Perfect for acquiring & retaining <strong>talent</strong> and enhancing employee <strong>wellbeing</strong>.
        </Typography>
        <AppButton
          title="Discover more"
          to="/corporate"
          as="a"
          background="black"
          size="base"
          // onClick={() => {
          //   setBusinessOpen(true);
          // }}
        />
      </div>
    </div>
  );
}

export default SectionBusinessEssentials;
