import React from "react";
import AppButton from "./AppButton";
import Typography from "./Typography";
import FadeInEffect from "./FadeInEffect";

function PriceCard({ priceItem }) {
  return (
    <FadeInEffect className="w-64">
      <img
        className="h-56 w-56 object-cover hidden sm:block"
        src={priceItem.img}
        alt={priceItem.title}
      />
      <Typography variant="h4" className="mt-4 mb-2">
        {priceItem.title}
      </Typography>
      <Typography variant="h3" className="mb-2">
        {priceItem.price}
      </Typography>
      <Typography variant="body2" className="text-gray-600 mb-5">
        {priceItem.desc}
      </Typography>
      <AppButton
        as="a"
        size="base"
        background="black"
        title="Learn more"
        to="/#subscriptions"
      />
    </FadeInEffect>
  );
}

export default PriceCard;
